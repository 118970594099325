import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You know how they say it’s good luck to have it rain on your wedding day? We believe the same is true when a hurricane hits on your first day of work.`}</p>
    <p>{`Such was the case for PayPerks’ newest addition, Rob Bernabé, who joined the team today as a software engineer and systems master. Rob comes to us from The Active Network where he spent 8 years growing into, in the words of one of his former colleagues, “one of the best, and possibly the best, system administrators around.” Rob’s process is as impressive as his output. He is an all around productivity expert who knows more keyboard shortcuts than just about anyone we’ve met.`}</p>
    <p>{`Storm or no storm, we feel ‘lucky’ to have Rob onboard Team PayPerks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      